import {
    CREATE_QUOTE_REQUEST,
    GET_ALL_VENDOR_CONFIGS,
    GET_QUOTE_ELIGIBILITY,
    GET_QUOTE_REQUEST,
    GET_QUOTE_RESPONSE,
    GET_QUOTE_SEARCH_RESULTS,
    GET_RATING_PAYLOAD,
    TOP_FIVE_COUNTRIES,
    TOP_FIVE_PRODUCERS
} from "../util/constants";

export const AppService = () => {
    const getTopFiveCounties = async (execute) => {
        return execute(
            TOP_FIVE_COUNTRIES.HTTP_METHOD,
            `${TOP_FIVE_COUNTRIES.URL}${getTodaysDate()}`
        );
    };

    const getTopFiveProducers = async (execute) => {
        return execute(
            TOP_FIVE_PRODUCERS.HTTP_METHOD,
            `${TOP_FIVE_PRODUCERS.URL}${getTodaysDate()}`
        )
    };

    const getQuoteRequest = async (execute, body) => {
        return execute(
            GET_QUOTE_REQUEST.HTTP_METHOD,
            GET_QUOTE_REQUEST.URL,
            body
        )
    };

    const getQuoteResponse = async (execute, body) => {
        return execute(
            GET_QUOTE_RESPONSE.HTTP_METHOD,
            GET_QUOTE_RESPONSE.URL,
            body
        )
    };

    const getRatingPayload = async (execute, body) => {
        return execute(
            GET_RATING_PAYLOAD.HTTP_METHOD,
            GET_RATING_PAYLOAD.URL,
            body
        )
    };

    const getQuoteEligibility = async (execute, body) => {
        return execute(
            GET_QUOTE_ELIGIBILITY.HTTP_METHOD,
            GET_QUOTE_ELIGIBILITY.URL,
            body
        )
    };

    const getQuoteSearchResults = async (execute, body) => {
        return execute(
            GET_QUOTE_SEARCH_RESULTS.HTTP_METHOD,
            GET_QUOTE_SEARCH_RESULTS.URL,
            body
        )
    };

    const createQuoteRequest = async (execute, body) => {
        return execute(
            CREATE_QUOTE_REQUEST.HTTP_METHOD,
            CREATE_QUOTE_REQUEST.URL,
            body
        )
    };

    const getAllVendorConfigs = async (execute) => {
        return execute(
            GET_ALL_VENDOR_CONFIGS.HTTP_METHOD,
            GET_ALL_VENDOR_CONFIGS.URL
        )
    };

    const getVendorConfigForAGivenUuid = async (execute, uuid) => {
        return execute(
            GET_ALL_VENDOR_CONFIGS.HTTP_METHOD,
            `${GET_ALL_VENDOR_CONFIGS.URL}?uuid=${uuid}`
        )
    };

    const getTodaysDate = () => {
        let today = new Date();
        const dd = String(today.getDate()).padStart(2, '0');
        const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        const yyyy = today.getFullYear();
        return yyyy + '-' + mm + '-' + dd;
    }

    return {
        getTopFiveCounties,
        getTopFiveProducers,
        getQuoteRequest,
        getQuoteResponse,
        getRatingPayload,
        getQuoteEligibility,
        getQuoteSearchResults,
        createQuoteRequest,
        getAllVendorConfigs,
        getVendorConfigForAGivenUuid
    }
}

export default AppService;