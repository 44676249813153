// creating Router
import {createBrowserRouter, createRoutesFromElements, Route,} from "react-router-dom";

import {LandingPage} from "../pages/home/LandingPage";
import React from "react";
import QuoteSearchPage from "../pages/quote-search/QuoteSearchPage";
import QuoteRequestPage from "../pages/quote-request/QuoteRequestPage";
import QuoteSubmitPage from "../pages/quote-submit/QuoteSubmitPage";
import AppLayoutPage from "../pages/general/AppLayoutPage";
import {createTheme} from "@mui/material";
import Error404 from "../pages/general/Error404";
import ProtectedAppLayoutPage from "../pages/general/ProtectedAppLayoutPage";
import jwt_decode from "jwt-decode";
import CarrierForm from "../pages/carrier-form/CarrierForm";
import VendorRenewalConfigurationForm from "../pages/renewal-config/VendorRenewalConfigurationForm";
import VendorRenewalConfigurationView from "../pages/renewal-config/VendorRenewalConfigurationView";

const uuidRegex =
    "^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$";

export const createRouter = ({msalInstance}) =>
    createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" element={<AppLayoutPage/>}>
                <Route index element={<LandingPage/>}/>
                <Route path="/" element={<ProtectedAppLayoutPage/>}>
                    <Route path="/home" element={<LandingPage/>}/>
                    <Route path="/quote-search" element={<QuoteSearchPage/>}/>
                    <Route path="/quote-submit" element={<QuoteSubmitPage/>}/>
                    <Route path="/dashboard" element={<QuoteRequestPage/>}/>
                    <Route path="/carrier-form" element={<CarrierForm/>}/>
                    <Route path="/vendor-renewal-view" element={<VendorRenewalConfigurationView/>}/>
                    <Route path="/vendor-renewal-config" element={<VendorRenewalConfigurationForm/>}/>
                </Route>
                <Route path="*" element={<Error404/>}/>
            </Route>
        )
    );
export const createCpicTheme = createTheme({
    palette: {
        cpicheaderbackgroundcolor: {
            main: '#0066a1',
        }
    },
});


export const isValidUUID = (val) => {
    if (val.length !== 36) return false;

    val = val.match(uuidRegex);
    return val !== null;

}

export const parseError = (err) => {
    let errorObject = err?.message;
    if (errorObject && JSON.parse(errorObject)?.errorMessage) {
        return JSON.parse(errorObject)?.errorMessage + " : " + JSON.parse(errorObject)?.additionalMessage;
    } else {
        return 'An error occurred!';
    }
}

export const isValidJSON = (jsonString) => {
    try {
        JSON.parse(jsonString);
        return true;
    } catch (e) {
        return false;
    }
};

export const hasTokenExpired = (token) => {
    let decodedToken = jwt_decode(token);
    let dateNow = new Date();
    return decodedToken?.exp < dateNow.getTime();
}