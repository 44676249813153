export const filterCriteriaOperations = ['IN', 'NOT_IN', 'BETWEEN', 'GREATER_THAN', 'LESS_THAN'];
export const filterCriteriaFields = ["ROOF_AGE", "POLICY_TYPE", "YEAR_BUILT", "EFFECTIVE_DATE", "COUNTY", "IS_FLOOD_ZONE"];
export const FIELD = 'field';
export const OPERATION_TYPE = 'operationType';
export const VALUE = 'value';
export const BASIC = 'Basic';
export const OAUTH2 = 'OAuth2';

export const TOP_FIVE_COUNTRIES = {
    HTTP_METHOD: 'GET',
    URL: '/api/admin/policy-info-report/county/'
};
export const TOP_FIVE_PRODUCERS = {
    HTTP_METHOD: 'GET',
    URL: '/api/admin/policy-info-report/producer/'
};
export const GET_QUOTE_REQUEST = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/get-quote-request'
};
export const GET_QUOTE_RESPONSE = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/get-quote-response'
};

export const GET_RATING_PAYLOAD = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/get-rating-payload'
};

export const GET_QUOTE_ELIGIBILITY = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/get-quote-eligibility'
};

export const GET_QUOTE_SEARCH_RESULTS = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/get-search-result'
};

export const CREATE_QUOTE_REQUEST = {
    HTTP_METHOD: 'POST',
    URL: '/api/admin/create-quote'
};

export const GET_ALL_VENDOR_CONFIGS = {
    HTTP_METHOD: 'GET',
    URL: '/api/admin/policy-renewal-config'
};